import {
  Button,
  Form,
  Input,
  Modal,
  RadioChangeEvent,
  Select,
  Switch,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Camera } from '../../Models/CameraSettings/Camera';
import { TypeCamera } from './TypeCamera';

export function AddCameraModal(props: {
  open: boolean;
  allData: Array<Camera>;
  data?: Camera;
  onCancel: Function;
  onCreate: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();

  const [removeItem, setRemoveItem] = useState(false);
  const [camera, setCamera] = useState<Camera>();

  const [takeOver, setTakeOver] = useState('new');

  const options = [
    { label: t('new'), value: 'new' },
    { label: t('takefrom'), value: 'takefrom' },
  ];

  useEffect(() => {
    form.resetFields();
    setCamera(props.data);
    setRemoveItem(false);
  }, [form, props.data, camera]);

  const onChangeSwitch = ({ target: { value } }: RadioChangeEvent) => {
    setTakeOver(value);
  };

  const TakeFromSelected = () => {
    if (takeOver == 'new') {
      return false;
    }
    return true;
  };

  const CreateNewSetting = (newData: Camera) => {
    props.onCreate(newData);
  };

  const onFinishForm = (values: Camera) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }

    if (removeItem) {
      props.onRemove(props.data);
      return;
    }

    CreateNewSetting(values);
  };

  const CheckLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.name === value);
    if (foundItem !== undefined) {
      if (foundItem.name === props.data?.name) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Dit label bestaat al'));
    }
    return Promise.resolve();
  };

  const CheckWhiteSpace = (_: any, value: string) => {
    if (props.data !== undefined) {
      if (value.indexOf(' ') >= 0) {
        return Promise.reject(new Error('Geen spaties toegestaan'));
      }
    }
    return Promise.resolve();
  };

  const GetTitle = () => {
    if (!props.onEdit) {
      return t('createcam');
    }
    return t('editcam');
  };

  const GetOkText = () => {
    if (!props.onEdit) {
      return t('create');
    }
    return t('safe');
  };

  const GetAvailableConfigs = () => {
    return [
      {
        value: TypeCamera.FrontCam,
        label: t('camfront'),
      },
      {
        value: TypeCamera.TopCam,
        label: t('camtop'),
      },
    ];
  };

  return (
    <Modal
      title={GetTitle()}
      open={props.open}
      cancelText={t('cancel')}
      onOk={() => form.submit()}
      onCancel={() => props.onCancel()}
      footer={[
        <Button key="back" onClick={() => props.onCancel()}>
          {t('cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {GetOkText()}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        autoComplete="off"
      >
        <Form.Item<string>
          label={t('name')}
          name="name"
          rules={[
            { required: true, message: t('enterinputfield').toString() },
            { validator: CheckLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<string>
          label={t('serialnumber')}
          name="serialNumber"
          rules={[
            { required: true, message: t('enterinputfield').toString() },
            { validator: CheckWhiteSpace },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<string>
          label={t('linktohost')}
          name="linkToHost"
          rules={[{ required: true, message: t('enterinputfield').toString() }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<string>
          label={t('type')}
          name="typeCamera"
          rules={[{ required: true, message: t('enterinputfield').toString() }]}
        >
          <Select
            placeholder={t('selectanoption')}
            allowClear
            options={GetAvailableConfigs()}
          ></Select>
        </Form.Item>
        <Form.Item<string>
          label={t('enabled')}
          name="enabled"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
}
