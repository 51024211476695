import axios, { AxiosResponse } from 'axios';
import { LocalHelper } from './LocalHelper';
import { saveAs } from 'file-saver';

export abstract class HttpHelper {
  public static IsRemoteHosted(): boolean {
    if (window.location.href.includes('cloudkwekerijbloemendaal')) {
    return true;
    } else {
      return false;
    }
  }

  public static GetUrlBaseApi(): string {
    // if (this.IsRemoteHosted()) {
    //   return LocalHelper.GetUrlForApiRemote();
    // } else {
    //   return LocalHelper.GetUrlForApiLocal();
    // }
    return LocalHelper.GetUrlForApiLocalHost();
  }

  public static GetUrlBaseApiHome(): string {
    // if (this.IsRemoteHosted()) {
    //   return LocalHelper.GetUrlForApiRemoteHome();
    // } else {
    //   return LocalHelper.GetUrlForApiLocalHome();
    // }
    return LocalHelper.GetUrlForApiLocalHost();
  }

  public static GetUrlStorageBaseApi() {
    if (this.IsRemoteHosted()) {
      return LocalHelper.GetUrlForStorageRemote();
    } else {
      return LocalHelper.GetUrlForStorageLocal();
    }
  }

  public static async PostRequestForApi<T>(
    url: string,
    item: any,
    token?: string,
  ): Promise<T> {
    const respon = axios({
      method: 'post',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(item),
    });

    return respon.then((response) => response.data).catch((error) => {});
  }

  public static async PostRequestForApiForFile<T>(
    url: string,
    item: any,
    token?: string,
  ): Promise<T> {
    const respon = axios({
      method: 'post',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
      data: JSON.stringify(item),
    });

    return respon
      .then((response) => {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim();
        let url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        return response.data;
      })
      .catch((error) => {});
  }

  public static async DeleteRequestForApi<T>(
    url: string,
    item?: any,
    token?: string,
  ): Promise<T> {
    const respon = axios({
      method: 'delete',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(item),
    });

    return respon
      .then((response) => response.data)
      .catch((error) => {
        return error.response;
      });
  }

  public static async GetRequestForApi<T>(
    url: string,
    token?: string,
  ): Promise<T>;
  public static async GetRequestForApi<T>(
    url: string,
    token?: string,
  ): Promise<Array<T>> {
    const respon = axios({
      method: 'get',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return respon.then((response) => response.data).catch((error) => {});
  }

  public static async UpdateRequestForApi<T>(
    url: string,
    item: any,
    token?: string,
  ): Promise<T> {
    const respon = axios({
      method: 'put',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(item),
    });
    return respon
      .then((response) => {
        response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static async AuthorizedRequest<T>(url: string, data: any) {
    let token = '';
    const respon = axios({
      method: 'post',
      url: url,
      timeout: 4000,
      headers: {
        'Access-Control-Allow-Origin': true,
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    });
    return respon.then((response) => response.data).catch((error) => {});
  }
}
